import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Posts from '../components/Posts/Posts'
import PrimaryButton from "../components/PrimaryButton/PrimaryButton"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Błąd 404 - Strony nie znaleziono" description="Błąd 404 - Strony nie znaleziono" keywords="" pathname="/404/" />
      <div className="page-top-section">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12">
              <p className="small-header text-center mb-0 text-white">404</p>
              <h1 className="header-h1 text-center mt-15 mb-30 text-white">Nie znaleziono strony</h1>
              <p className="text-center paragraph mb-15 text-white">
                  Przepraszamy{" "}
                  <span role="img" aria-label="Pensive emoji">
                  😔
                  </span>{" "}
                  nie znaleźliśmy strony której szukasz.
              </p>
              
              <div className="d-flex alignt-items-center justify-content-center">
                <PrimaryButton link="/" title="Wróć na stronę główną" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Posts />
    </Layout>
  )
}

export default NotFoundPage
